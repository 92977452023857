(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/assets/javascripts/handlebars-helpers.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const TARGET_CAROUSEL = 'carousel';
const TARGET_ITEM = 'item';
const CssClassSuffix = Object.freeze({
  PREV: 'prev',
  CURR: 'curr',
  NEXT: 'next'
});
if (typeof exports === 'object') {
  registerHelpers(require('hbs'), {
    isServerSide: true
  });
} else {
  registerHelpers(Handlebars, {
    isServerSide: false
  });
}

function registerHelpers(hbs, _ref) {
  let {
    isServerSide
  } = _ref;
  const _renderWithCompiledTemplate = (target, model) => {
    const compiledTemplate = isServerSide ? hbs.cache["components-lb_ui-products_carousel-".concat(target)] :
    svs.lb_ui.products_carousel.templates[target];
    return compiledTemplate ? compiledTemplate(model) : '';
  };

  hbs.registerHelper('products-carousel', function productsCarouselHelper(target, options) {
    const itemData = collectCarouselItemsData(target, options, this);
    if (target !== TARGET_CAROUSEL || itemData.length === 0) {
      return '';
    }
    const carouselModel = _objectSpread(_objectSpread({}, options.hash), {}, {
      _itemCount: itemData.length,
      _children: ''
    });
    itemData.forEach((_ref2, index) => {
      let {
        children,
        optionsHash
      } = _ref2;
      const initialSetupsByIndex = {
        [itemData.length - 1]: CssClassSuffix.PREV,
        0: CssClassSuffix.CURR,
        1: CssClassSuffix.NEXT
      };
      const itemModel = _objectSpread(_objectSpread({}, optionsHash), {}, {
        _children: children,
        _itemSetupPos: index + 1,
        _itemSetupInitial: initialSetupsByIndex[index] || null
      });
      carouselModel._children += _renderWithCompiledTemplate(TARGET_ITEM, itemModel) || '';
    });
    return _renderWithCompiledTemplate(TARGET_CAROUSEL, carouselModel) || '';
  });
}

function collectCarouselItemsData(target, options, hbsContext) {
  if (target === TARGET_CAROUSEL) {
    options.data._carouselItemData = [];
    options.fn(hbsContext);
    const itemData = options.data._carouselItemData;
    delete options.data._carouselItemData;
    return itemData;
  }
  if (target === TARGET_ITEM) {
    options.data._carouselItemData.push({
      children: options.fn(hbsContext),
      optionsHash: _objectSpread({}, options.hash)
    });
    return null;
  }
}

 })(window);