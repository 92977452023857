(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/assets/javascripts/init.js');
"use strict";



const {
  CarouselEventManager
} = svs.components.lbUi.productsCarousel;
const init = element => {
  const allCarouselContainers = element && element.getElementsByClassName('js-products-carousel') || [];
  for (let i = 0; i < allCarouselContainers.length; i++) {
    const carouselContainer = allCarouselContainers[i];
    const {
      isInitialized,
      clickSelector
    } = carouselContainer.dataset;
    if (!isInitialized) {
      carouselContainer.dataset.isInitialized = 'true';
      const manager = new CarouselEventManager({
        carouselContainer,
        itemGapPx: 16,
        clickSelector
      });
      manager.initialize();
    }
  }
};
setGlobal('svs.components.lbUi.productsCarousel.init', init);

 })(window);