(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/assets/javascripts/translation.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/assets/javascripts/translation.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const REMOVE_ARTIFACTS = 'translateZ(-1px)';
const SUPPORTED_ELEVATIONS = [100, 200, 300, 400, 600, 800, 1200, 1600, 2400];
const PX_GAP_BETWEEN_ITEMS = 12;

class CarouselTranslation {
  constructor(_ref) {
    let {
      itemCount,
      itemWidthPx,
      itemGapPx
    } = _ref;
    this.itemCount = itemCount;
    this.itemWidthPx = itemWidthPx;
    this.itemGapPx = itemGapPx;
    this.currPan = null;
  }

  setItemWidth(itemWidthPx) {
    this.itemWidthPx = itemWidthPx;
  }

  setCurrPan(panPercent) {
    const maxPan = 100 * this.itemCount;
    this.currPan = Math.round(panPercent);
    while (this.currPan < 0) {
      this.currPan += maxPan;
    }
    while (this.currPan > maxPan) {
      this.currPan -= maxPan;
    }
    this.currPanRelative = this.currPan % 100;
    if (this.currPanRelative > 50) {
      this.currPanRelative -= 100;
    }
    if (this.currPan <= 50 || this.currPan > maxPan - 50) {
      this.currIndex = 0;
    } else if (this.currPanRelative === 50) {
      this.currIndex = Math.round(this.currPan / 100) - 1;
    } else {
      this.currIndex = Math.round(this.currPan / 100);
    }
    switch (this.itemCount) {
      case 1:
        this.prevIndex = null;
        this.nextIndex = null;
        break;
      case 2:
        this.prevIndex = this.currIndex === 0 ? null : 0;
        this.nextIndex = this.currIndex === 0 ? 1 : null;
        break;
      default:
        if (this.currPanRelative === 50) {
          this.prevIndex = null;
          this.nextIndex = this.currIndex === this.itemCount - 1 ? 0 : this.currIndex + 1;
        } else {
          this.prevIndex = this.currIndex === 0 ? this.itemCount - 1 : this.currIndex - 1;
          this.nextIndex = this.currIndex === this.itemCount - 1 ? 0 : this.currIndex + 1;
        }
        break;
    }
  }

  getCurrItemData(itemIndex) {
    const _getTransformData = percent => {
      const scalePercent = this._scaleValueUsingPercent({
        percent,
        valueAtMinusHundredPercent: 80,
        valueAtZeroPercent: 100,
        valueAtHundredPercent: 80
      });
      const translatePercent = this._scaleValueUsingPercent({
        percent,
        valueAtMinusHundredPercent: 40,
        valueAtZeroPercent: -50,
        valueAtHundredPercent: -140
      });
      const translateGapPx = this._scaleValueUsingPercent({
        percent,
        valueAtMinusHundredPercent: PX_GAP_BETWEEN_ITEMS,
        valueAtZeroPercent: 0,
        valueAtHundredPercent: -PX_GAP_BETWEEN_ITEMS
      });
      const elevationIndex = Math.round(this._scaleValueUsingPercent({
        percent,
        valueAtMinusHundredPercent: 0,
        valueAtZeroPercent: SUPPORTED_ELEVATIONS.length - 1,
        valueAtHundredPercent: 0
      }));
      return {
        scale: scalePercent === 100 ? null : Math.round(scalePercent) / 100,
        translation1: Math.round(translatePercent) ? "".concat(Math.round(translatePercent), "%,0") : null,
        translation2: Math.round(translateGapPx) ? "".concat(Math.round(translateGapPx), "px,0") : null,
        elevation: SUPPORTED_ELEVATIONS[elevationIndex] || SUPPORTED_ELEVATIONS[0]
      };
    };
    if (itemIndex === this.prevIndex) {
      return _objectSpread({
        order: 1,
        isPrev: true
      }, _getTransformData(this.currPanRelative + 100));
    }
    if (itemIndex === this.currIndex) {
      return _objectSpread({
        order: 2,
        isCurr: true
      }, _getTransformData(this.currPanRelative));
    }
    if (itemIndex === this.nextIndex) {
      return _objectSpread({
        order: 3,
        isNext: true
      }, _getTransformData(this.currPanRelative - 100));
    }
    return {
      isHidden: true
    };
  }

  _scaleValueUsingPercent(_ref2) {
    let {
      percent,
      valueAtMinusHundredPercent,
      valueAtZeroPercent,
      valueAtHundredPercent
    } = _ref2;
    if (percent === 0) {
      return valueAtZeroPercent;
    }
    if (percent < 0) {
      return percent * (valueAtZeroPercent - valueAtMinusHundredPercent) / 100 + valueAtZeroPercent;
    }
    return percent * (valueAtHundredPercent - valueAtZeroPercent) / 100 + valueAtZeroPercent;
  }

  getCurrItemStyle(itemIndex) {
    const {
      isHidden,
      order,
      scale,
      translation1,
      translation2
    } = this.getCurrItemData(itemIndex);
    if (isHidden) {
      return 'display: none;';
    }
    const sortedTransformations = [REMOVE_ARTIFACTS, translation1 ? "translate(".concat(translation1, ")") : null, translation2 ? "translate(".concat(translation2, ")") : null, scale ? "scale(".concat(scale, ")") : null].filter(Boolean);
    return "display: flex; order: ".concat(order, "; transform: ").concat(sortedTransformations.join(' '), ";");
  }

  getCurrItemAdaptedClasses(itemIndex, currCssClasses) {
    const {
      isHidden,
      elevation
    } = this.getCurrItemData(itemIndex);
    if (isHidden || !elevation) {
      return currCssClasses;
    }
    const cssClassesWithoutElevation = currCssClasses.split(/\s+/).filter(c => !c.startsWith('elevation-')).join(' ');
    return cssClassesWithoutElevation ? "".concat(cssClassesWithoutElevation, " elevation-").concat(elevation) : "elevation-".concat(elevation);
  }
}

setGlobal('svs.components.lbUi.productsCarousel.translation', {
  CarouselTranslation
});

 })(window);