(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/assets/javascripts/event-manager.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/assets/javascripts/event-manager.js');
"use strict";



const {
  CarouselTranslation
} = svs.components.lbUi.productsCarousel.translation;
const mockableImports = {
  getScrollLeftForEverySnapAlignTarget: svs.components.lbUi.productsCarousel.scrollHelpers.getScrollLeftForEverySnapAlignTarget
};
const ScrollActionStates = Object.freeze({
  IDLE: 'idle',
  MOVING_OLD_SCROLL_POSITIONS: 'moving'
});
const ScrollFineTuning = Object.freeze({
  PIXELS_DIFF_TO_DETECT_NEARBY_SCROLL_ITEM: 10,
  MS_OF_MAX_FIRING_RATE_TO_DETECT_OLD_SCROLL_EVENTS: 100,
  HAS_TO_TEMPORARILY_BLOCK_SCROLLING_BY_HIDING_OVERLAY: true
});

class CarouselEventManager {
  constructor(_ref) {
    let {
      carouselContainer,
      itemGapPx,
      clickSelector = null
    } = _ref;
    this.flags = Object.seal({
      hasToSkipNextCarouselUpdate: false,
      hasOngoingScrollEvent: false,
      hasOngoingTouchEvent: false,
      hasToFinalizeLatestScrollEvent: false,
      hasGotScrollActionSinceLatestDoubleCheck: false
    });
    this.elements = Object.seal({
      carouselContainer,
      scrollOverlay: carouselContainer.getElementsByClassName('js-products-carousel-scroll-overlay')[0],
      items: carouselContainer.getElementsByClassName('js-products-carousel-item')
    });
    this.data = Object.seal({
      currIndex: 0,
      maxScrollLeft: null,
      itemCount: this.elements.items.length,
      doubleCheckInterval: null
    });
    this.translations = new CarouselTranslation({
      itemCount: this.data.itemCount,
      itemWidthPx: this.elements.items[0].getBoundingClientRect().width,
      itemGapPx
    });
    this.handleSwitchItemData = Object.seal({
      state: ScrollActionStates.IDLE,
      hasToDecreaseCurrIndex: false,
      hasToIncreaseCurrIndex: false,
      moveScrollEventsTimeoutHandle: null
    });
    this.clickSelector = clickSelector;
  }

  initialize() {
    const {
      scrollOverlay
    } = this.elements;
    scrollOverlay.setAttribute('style', 'display: flex;');
    const scrollPositions = mockableImports.getScrollLeftForEverySnapAlignTarget(scrollOverlay);
    if (scrollPositions[1]) {
      scrollOverlay.scrollTo(scrollPositions[1], 0);
    }
    this._setupEvents();
    this._updateDebugInfo(0);
    return this;
  }
  _setupEvents() {
    const {
      scrollOverlay
    } = this.elements;
    const _addClickHandlerAndAdaptCss = (cssClassOfElement, handler) => {
      const element = scrollOverlay.getElementsByClassName(cssClassOfElement)[0];
      if (element) {
        element.addEventListener('click', handler);
        const hasCssClassPointer = /\bpointer\b/.test(element.getAttribute('class') || '');
        if (!hasCssClassPointer) {
          element.setAttribute('class', "".concat(element.getAttribute('class') || '', " pointer").trim());
        }
      }
    };
    switch (this.data.itemCount) {
      case 1:
        if (this.clickSelector) {
          _addClickHandlerAndAdaptCss('js-overlay-center', this._onClickFor1stItem.bind(this));
        }
        break;
      case 2:
        scrollOverlay.addEventListener('scroll', this._onScrollUpdateForTwoItems.bind(this));
        if (this.clickSelector) {
          _addClickHandlerAndAdaptCss('js-overlay-center', this._onClickFor1stItem.bind(this));
          _addClickHandlerAndAdaptCss('js-overlay-right', this._onClickFor2ndItem.bind(this));
        }
        break;
      default:
        scrollOverlay.addEventListener('scroll', this._onScrollUpdateForManyItems.bind(this));
        if (this.clickSelector) {
          _addClickHandlerAndAdaptCss('js-overlay-center', this._onClick.bind(this));
        }
        break;
    }
    return this;
  }

  _turnScrollPositionIntoPanPercentage(scrollLeft) {
    const {
      scrollOverlay
    } = this.elements;
    const scrollPositions = mockableImports.getScrollLeftForEverySnapAlignTarget(scrollOverlay);
    const scrollRange = {
      lowestPositive: Math.max(0, Math.min(...scrollPositions)),
      highestPositive: Math.max(0, ...scrollPositions)
    };
    if (scrollRange.lowestPositive === scrollRange.highestPositive) {
      return 100 * this.data.currIndex;
    }
    const totalScrollRange = scrollRange.highestPositive - scrollRange.lowestPositive;
    const panRelativeBetweenZeroAndOne = (scrollLeft - scrollRange.lowestPositive) / totalScrollRange;
    if (this.data.itemCount === 2) {
      return 100 * panRelativeBetweenZeroAndOne;
    }
    const panRelativeBetweenMinusHundredAndPlusHundred = 200 * panRelativeBetweenZeroAndOne - 100;
    return 100 * this.data.currIndex + panRelativeBetweenMinusHundredAndPlusHundred;
  }
  _onScrollUpdateForTwoItems(event) {
    const newPan = this._turnScrollPositionIntoPanPercentage(event.target.scrollLeft);
    this.translations.setCurrPan(newPan);
    this._refreshItemStyles();
    this._updateDebugInfo(newPan);
  }
  _onScrollUpdateForManyItems(event) {
    const newScrollLeft = event.target.scrollLeft;
    const {
      scrollOverlay
    } = this.elements;
    const scrollPositions = mockableImports.getScrollLeftForEverySnapAlignTarget(scrollOverlay);
    const [scrollPosLeftItem, scrollPosCenterItem, scrollPosRightItem] = scrollPositions;
    const _helpers = Object.freeze({
      refreshTranslationAndItems: _ref2 => {
        let {
          scrollLeft
        } = _ref2;
        const newPan = this._turnScrollPositionIntoPanPercentage(scrollLeft);
        this.translations.setCurrPan(newPan);
        this._refreshItemStyles();
        this._updateDebugInfo(newPan);
      },
      jumpToScrollCenterAndStartMovingOldScrollPositions: _ref3 => {
        let {
          hasToDecreaseCurrIndex,
          hasToIncreaseCurrIndex
        } = _ref3;
        this.handleSwitchItemData.hasToDecreaseCurrIndex = hasToDecreaseCurrIndex;
        this.handleSwitchItemData.hasToIncreaseCurrIndex = hasToIncreaseCurrIndex;
        if (ScrollFineTuning.HAS_TO_TEMPORARILY_BLOCK_SCROLLING_BY_HIDING_OVERLAY) {
          scrollOverlay.scrollTo(scrollPosCenterItem, 0);
          scrollOverlay.setAttribute('style', 'display: none;');
        }
        _helpers.adjustCurrItem();
        _helpers.initOrRefreshTimeoutOfMovingOldScrollPositions();
        if (!ScrollFineTuning.HAS_TO_TEMPORARILY_BLOCK_SCROLLING_BY_HIDING_OVERLAY) {
          window.requestAnimationFrame(() => {
            scrollOverlay.scrollTo(scrollPosCenterItem, 0);
          });
        }
        _helpers.refreshTranslationAndItems({
          scrollLeft: scrollPosCenterItem
        });
      },
      adjustCurrItem: () => {
        if (this.handleSwitchItemData.hasToDecreaseCurrIndex) {
          this.data.currIndex = this.data.currIndex > 0 ? this.data.currIndex - 1 : this.data.itemCount - 1;
          this.handleSwitchItemData.hasToDecreaseCurrIndex = false;
        }
        if (this.handleSwitchItemData.hasToIncreaseCurrIndex) {
          this.data.currIndex = this.data.currIndex < this.data.itemCount - 1 ? this.data.currIndex + 1 : 0;
          this.handleSwitchItemData.hasToIncreaseCurrIndex = false;
        }
      },
      initOrRefreshTimeoutOfMovingOldScrollPositions: () => {
        if (this.handleSwitchItemData.moveScrollEventsTimeoutHandle) {
          clearTimeout(this.handleSwitchItemData.moveScrollEventsTimeoutHandle);
        }
        this.handleSwitchItemData.moveScrollEventsTimeoutHandle = setTimeout(_helpers.stopMovingOldScrollPositions, ScrollFineTuning.MS_OF_MAX_FIRING_RATE_TO_DETECT_OLD_SCROLL_EVENTS);
        this.handleSwitchItemData.state = ScrollActionStates.MOVING_OLD_SCROLL_POSITIONS;
      },
      stopMovingOldScrollPositions: () => {
        this.handleSwitchItemData.moveScrollEventsTimeoutHandle = null;
        this.handleSwitchItemData.state = ScrollActionStates.IDLE;
        if (ScrollFineTuning.HAS_TO_TEMPORARILY_BLOCK_SCROLLING_BY_HIDING_OVERLAY) {
          window.requestAnimationFrame(() => {
            scrollOverlay.setAttribute('style', 'display: flex;');
            scrollOverlay.scrollTo(scrollPosCenterItem, 0);
          });
        }
      }
    });
    const nearbyScrollItem = Object.seal({
      isLeft: Math.abs(newScrollLeft - scrollPosLeftItem) < ScrollFineTuning.PIXELS_DIFF_TO_DETECT_NEARBY_SCROLL_ITEM,
      isCenter: Math.abs(newScrollLeft - scrollPosCenterItem) < ScrollFineTuning.PIXELS_DIFF_TO_DETECT_NEARBY_SCROLL_ITEM,
      isRight: Math.abs(newScrollLeft - scrollPosRightItem) < ScrollFineTuning.PIXELS_DIFF_TO_DETECT_NEARBY_SCROLL_ITEM
    });
    switch (this.handleSwitchItemData.state) {
      default:
      case ScrollActionStates.IDLE:
        if (nearbyScrollItem.isLeft || nearbyScrollItem.isRight) {
          _helpers.jumpToScrollCenterAndStartMovingOldScrollPositions({
            hasToDecreaseCurrIndex: nearbyScrollItem.isLeft,
            hasToIncreaseCurrIndex: nearbyScrollItem.isRight
          });
        }
        _helpers.refreshTranslationAndItems({
          scrollLeft: newScrollLeft
        });
        break;
      case ScrollActionStates.MOVING_OLD_SCROLL_POSITIONS:
        {
          if (!nearbyScrollItem.isCenter) {
            window.requestAnimationFrame(() => {
              scrollOverlay.scrollTo(scrollPosCenterItem, 0);
            });
            _helpers.initOrRefreshTimeoutOfMovingOldScrollPositions();
          }
          _helpers.refreshTranslationAndItems({
            scrollLeft: scrollPosCenterItem
          });
          break;
        }
    }
  }
  _onClickFor1stItem() {
    this.data.currIndex = 0;
    this._onClick();
  }
  _onClickFor2ndItem() {
    this.data.currIndex = 1;
    this._onClick();
  }
  _onClick() {
    const currItem = this.elements.items[this.data.currIndex];
    if (currItem && this.clickSelector) {
      const elementToReceiveClickEvent = currItem.querySelector(this.clickSelector);
      if (elementToReceiveClickEvent) {
        elementToReceiveClickEvent.click();
      }
    }
  }
  _refreshItemStyles() {
    const newItemStyles = [];
    for (let itemIndex = 0; itemIndex < this.elements.items.length; itemIndex++) {
      newItemStyles.push(this.translations.getCurrItemStyle(itemIndex));
    }
    const newItemClasses = [];
    for (let itemIndex = 0; itemIndex < this.elements.items.length; itemIndex++) {
      const currClasses = this.elements.items[itemIndex].getAttribute('class') || '';
      newItemClasses.push(this.translations.getCurrItemAdaptedClasses(itemIndex, currClasses));
    }
    window.requestAnimationFrame(() => {
      for (let itemIndex = 0; itemIndex < this.elements.items.length; itemIndex++) {
        this.elements.items[itemIndex].setAttribute('style', newItemStyles[itemIndex]);
        this.elements.items[itemIndex].setAttribute('class', newItemClasses[itemIndex]);
      }
    });
  }
  _updateDebugInfo(currPan) {
    this.elements.carouselContainer.dataset.currIndex = this.data.currIndex;
    this.elements.carouselContainer.dataset.currPan = Math.round(currPan);
  }
}
const isTestEnvironment = typeof sinon !== 'undefined' && typeof describe !== 'undefined';
if (isTestEnvironment) {
  CarouselEventManager._testInternals = {
    getMockableImports: () => mockableImports
  };
}

setGlobal('svs.components.lbUi.productsCarousel.CarouselEventManager', CarouselEventManager);

 })(window);