(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/assets/javascripts/scroll-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/assets/javascripts/scroll-helpers.js');
"use strict";



function getHandlerForAdvancedScrollEvents(inputBag) {
  const {
    timeoutMS = 100,
    onFirstEvent = null,
    onEveryEvent = null,
    onFinalEvent = null,
    onFirstError = null
  } = inputBag;
  let timeoutHandle = null;
  let errorCount = 0;
  let ticking = false;
  let latestEvent = null;
  const _runHandlerAndCatchErrors = (handler, event) => {
    try {
      if (handler) {
        handler(event);
      }
    } catch (error) {
      errorCount++;
      if (errorCount === 1 && onFirstError) {
        onFirstError(error);
      }
    }
  };
  return event => {
    if (timeoutHandle === null) {
      _runHandlerAndCatchErrors(onFirstEvent, event);
    } else {
      clearTimeout(timeoutHandle);
    }
    latestEvent = event;
    if (!ticking) {
      window.requestAnimationFrame(() => {
        _runHandlerAndCatchErrors(onEveryEvent, latestEvent);
        ticking = false;
      });
      ticking = true;
    }
    timeoutHandle = setTimeout(() => {
      timeoutHandle = null;
      _runHandlerAndCatchErrors(onFinalEvent, event);
      errorCount = 0;
    }, timeoutMS);
  };
}

function getScrollLeftForEverySnapAlignTarget(itemsContainer) {
  if (!itemsContainer) {
    return [];
  }
  const {
    width: itemsContainerOuterWidth,
    left: itemsCountainerViewportLeft
  } = itemsContainer.getBoundingClientRect();
  const _getScrollSnapAlign = element => {
    const value = element.dataset.backupStyle || window.getComputedStyle(element).scrollSnapAlign;
    const hasMoreThanOneValue = value.includes(' ');
    return hasMoreThanOneValue ? value.split(' ').filter(Boolean)[0] || '' : value;
  };
  const results = [];
  for (let i = 0; i < itemsContainer.children.length; i++) {
    const itemElement = itemsContainer.children[i];
    const {
      width: itemOuterWidth,
      left: itemViewportLeft
    } = itemElement.getBoundingClientRect();
    const itemLeftInParent = itemViewportLeft - itemsCountainerViewportLeft + itemsContainer.scrollLeft;
    const handlerForSupportedCssValues = {
      start: () => results.push(itemLeftInParent),
      center: () => {
        const itemCenter = itemLeftInParent + itemOuterWidth / 2;
        results.push(itemCenter - itemsContainerOuterWidth / 2);
      },
      end: () => results.push(itemLeftInParent + itemOuterWidth - itemsContainerOuterWidth)
    };
    const scrollSnapAlign = _getScrollSnapAlign(itemElement);
    if (handlerForSupportedCssValues[scrollSnapAlign]) {
      handlerForSupportedCssValues[scrollSnapAlign]();
    }
  }
  return results;
}

setGlobal('svs.components.lbUi.productsCarousel.scrollHelpers', {
  getHandlerForAdvancedScrollEvents,
  getScrollLeftForEverySnapAlignTarget
});

 })(window);