(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/products-carousel/views/carousel.js') >= 0) return;  svs.modules.push('/components/lb-ui/products-carousel/views/carousel.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.lb_ui=_svs.lb_ui||{};
_svs.lb_ui.products_carousel=_svs.lb_ui.products_carousel||{};
_svs.lb_ui.products_carousel.templates=_svs.lb_ui.products_carousel.templates||{};
svs.lb_ui.products_carousel.templates.carousel = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"products-carousel js-products-carousel"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"layout") : depth0),"===","optional",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":130}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"_itemCount") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":74}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"class") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":36}}})) != null ? stack1 : "")
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"style") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":7,"column":42}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"clickSelector") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":8,"column":72}}})) != null ? stack1 : "")
    + ">\n  <div class=\"products-carousel-size\">\n    <div class=\"aspect-ratio-box\"></div>\n  </div>\n  <div class=\"products-carousel-items\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"_children") || (depth0 != null ? lookupProperty(depth0,"_children") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"_children","hash":{},"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":21}}}) : helper))) != null ? stack1 : "")
    + "</div>\n  <div class=\"products-carousel-ignore-clicks-when-scroll-overlay-is-hidden\"></div>\n  <div class=\"products-carousel-scroll-overlay js-products-carousel-scroll-overlay\">\n    <div class=\"products-carousel-scroll-boundary-for-safari\"></div>\n    <div class=\"products-carousel-scroll-item overlay-left js-overlay-left\"></div>\n    <div class=\"products-carousel-scroll-item overlay-center js-overlay-center\"></div>\n    <div class=\"products-carousel-scroll-item overlay-right js-overlay-right\"></div>\n    <div class=\"products-carousel-scroll-boundary-for-safari\"></div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " products-carousel-layout-optional";
},"4":function(container,depth0,helpers,partials,data) {
    return " products-carousel-layout-standard";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " products-carousel-itemcount-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"_itemCount") || (depth0 != null ? lookupProperty(depth0,"_itemCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"_itemCount","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":66}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"class","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":28}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " style=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"style") || (depth0 != null ? lookupProperty(depth0,"style") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"style","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":33}}}) : helper)))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-click-selector=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"clickSelector") || (depth0 != null ? lookupProperty(depth0,"clickSelector") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"clickSelector","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":63}}}) : helper)))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_children") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":25,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-lb_ui-products_carousel-carousel'] = svs.lb_ui.products_carousel.templates.carousel;
})(svs, Handlebars);


 })(window);